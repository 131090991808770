const menuAdmin = [
  {
    text: "Home",
    link: "/administrator/dashboard",
    icon: "icon-grid",
  },
  {
    text: "Search",
    link: "/administrator/contacts",
    icon: "icon-notebook",
  },
  {
    text: "Inbox",
    link: "/administrator/inbox",
    icon: "icon-envelope",
  },
  {
    text: "Communications",
    link: "/administrator/communications-log",
    icon: "icon-bubbles",
  },
  {
    text: "Resources",
    link: "/administrator/resources",
    icon: "icon-docs",
  },
  {
    text: "Preferences",
    link: "/administrator/setting",
    icon: "icon-settings",
  }
];
const menuParent = [
  {
    text: "Home",
    link: "/parent/dashboard",
    icon: "fist-raised",
  },
  {
    text: "Students",
    link: "/parent/students",
    icon: "icon-user",
  },
  {
    text: "Calendar",
    link: "/parent/calendar",
    icon: "icon-calendar",
  },
  {
    text: "Contacts",
    link: "/parent/contacts",
    icon: "icon-notebook",
  },
  {
    text: "Inbox",
    link: "/parent/inbox",
    icon: "icon-envelope",
  },
  {
    text: "News & Events",
    link: "/parent/feeds",
    icon: "icon-book-open",
  },
  {
    text: "Resources",
    link: "/parent/resource-center",
    icon: "icon-docs",
  },
  {
    text: "FAQ",
    link: "/parent/help",
    icon: "icon-question",
  },
  {
    text: "District Support",
    link: "/parent/help",
    icon: "icon-question",
  },
  {
    text: "Preferences",
    link: "/parent/settings",
    icon: "icon-settings",
  },  {
    text: "Surveys",
    link: "/parent/surveys",
    icon: "icon-settings",
  },
];
const menuStudent = [
  {
    text: "Home",
    link: "/student/dashboard",
    icon: "icon-grid",
  },
  {
    text: "My Timeline",
    link: "/student/profile",
    icon: "icon-list",
  },
  {
    text: "Contacts",
    link: "/student/contacts/",
    icon: "icon-notebook",
  },
  {
    text: "Inbox",
    link: "/student/inbox",
    icon: "icon-envelope",
  },
  {
    text: "Calendar",
    link: "/student/calendar",
    icon: "icon-calendar",
  },
  {
    text: "News & Events",
    link: "/student/feeds",
    icon: "icon-book-open",
  },
  {
    text: "Resources",
    link: "/student/resource-center",
    icon: "icon-docs",
  },
  {
    text: "FAQ",
    link: "/student/help",
    icon: "icon-question",
  },
  {
    text: "Preferences",
    link: "/student/settings",
    icon: "icon-settings",
  },
];
const menuTeacher = [
  {
    text: "Home",
    link: "/teacher/dashboard",
    icon: "icon-grid",
  },
  {
    text: "Classes",
    link: "/teacher/classes",
    icon: "icon-layers",
  },
  {
    text: "Expectations",
    link: "/teacher/expectations",
    icon: "icon-bulb",
  },
  {
    text: "Files",
    link: "/teacher/files",
    icon: "icon-folder-alt",
  },
  {
    text: "Certificates",
    link: "/teacher/certificates",
    icon: "icon-badge",
  },
  {
    text: "Surveys",
    link: "/teacher/survey-manager",
    icon: "icon-puzzle",
  },
  {
    text: "Communications",
    link: "/teacher/communications-log",
    icon: "icon-bubbles",
  },
  {
    text: "Inbox",
    link: "/teacher/inbox",
    icon: "icon-envelope",
  },
  {
    text: "Contacts",
    link: "/teacher/contacts",
    icon: "icon-notebook",
  },
  {
    text: "Resources",
    link: "/teacher/resources",
    icon: "icon-docs",
  },
  {
    text: "Preferences",
    link: "/teacher/setting",
    icon: "icon-settings",
  },
  // {
  //   text: 'My Comm. Groups',
  //   link: '/teacher/communication-groups',
  //   icon: null,
  // },
  // {
  //   text: "Calendar",
  //   link: "/teacher/calendar",
  //   icon: null,
  // },
];
const menuCounselor = [
  {
    text: "Home",
    link: "/counselor/dashboard",
    icon: "icon-grid",
  },
  {
    text: "Calendar",
    link: "/counselor/calendar",
    icon: "icon-calendar",
  },
  // {
  //   text: "News",
  //   link: "/counselor/feeds",
  //   icon: "icon-feed",
  // },
  {
    text: "Files",
    link: "/counselor/files",
    icon: "icon-folder-alt",
  },
  {
    text: "Surveys",
    link: "/counselor/survey-manager",
    icon: "icon-puzzle",
  },
  {
    text: "Checklists",
    link: "/counselor/checklists",
    icon: "icon-list",
  },
  {
    text: "Communications",
    link: "/counselor/communication-groups",
    icon: "icon-bubbles",
  },
  {
    text: "Inbox",
    link: "/counselor/inbox",
    icon: "icon-envelope",
  },
  {
    text: "Contacts",
    link: "/counselor/contacts",
    icon: "icon-notebook",
  },
  {
    text: "Resources",
    link: "/counselor/resources",
    icon: "icon-docs",
  },
  {
    text: "Preferences",
    link: "/counselor/setting",
    icon: "icon-settings",
  },
  // {
  //   text: 'My Comm. Groups',
  //   link: '/teacher/communication-groups',
  //   icon: null,
  // },
  // {
  //   text: "Calendar",
  //   link: "/teacher/calendar",
  //   icon: null,
  // },
];
export const menuProfile = {
  Administrator: menuAdmin,
  Student: menuStudent,
  Parent: menuParent,
  administrator: menuAdmin,
  student: menuStudent,
  parent: menuParent,
  Teacher: menuTeacher,
  Counselor:menuCounselor,
  counselor:menuCounselor
};
